exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-career-js": () => import("./../../../src/pages/en/career.js" /* webpackChunkName: "component---src-pages-en-career-js" */),
  "component---src-pages-en-competences-js": () => import("./../../../src/pages/en/competences.js" /* webpackChunkName: "component---src-pages-en-competences-js" */),
  "component---src-pages-en-contact-confirmation-js": () => import("./../../../src/pages/en/contact-confirmation.js" /* webpackChunkName: "component---src-pages-en-contact-confirmation-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-cookies-policy-js": () => import("./../../../src/pages/en/cookies-policy.js" /* webpackChunkName: "component---src-pages-en-cookies-policy-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-team-js": () => import("./../../../src/pages/en/team.js" /* webpackChunkName: "component---src-pages-en-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kompetencje-js": () => import("./../../../src/pages/kompetencje.js" /* webpackChunkName: "component---src-pages-kompetencje-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-o-kancelarii-js": () => import("./../../../src/pages/o-kancelarii.js" /* webpackChunkName: "component---src-pages-o-kancelarii-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-templates-competence-js": () => import("./../../../src/templates/competence.js" /* webpackChunkName: "component---src-templates-competence-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

